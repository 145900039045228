<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
              :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.dashboard"
                             class="detail-page__head-back"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></div>
                  <LinkBack
                          :value="$t('common_goToDashboard.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_scanOrderFBMLabel'])"></div>
              {{$t('fbm_scanOrderFBMLabel.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_scanOrderFBMLabel'])"></div>
                <SearchInput
                    :type="'text'"
                    :label="$t('fbm_scanOrderFBMLabel.localization_value.value')"
                    v-model="barcode"
                    @submit="scan($event)"
                    @keyup.native="scan($event, true)"
                />
                <div class="color-red pt-2"
                     v-if="barcodeErrorItem !== ''"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['scanBarcode_notFound'])"></div>
                  {{"'" + barcodeErrorItem + "'"}} {{$t('scanBarcode_notFound.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.dashboard"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import SearchInput from "../../../../../UI/inputs/SearchInput/SearchInput";

  export default {
    name: "ScanBarcodeFBMAdmin",

    components: {
      SearchInput,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
    },

    data() {
      return {
        barcode: '',
        barcodeErrorItem: '',
      }
    },

    methods: {
      scan(e, checkPressedKey = false) {
        console.log(e);
        if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

        //reset error
        this.barcodeErrorItem = ''

        this.$store.dispatch('getScanBarcodeOrdersFBMLabel', this.barcode).then((response) => {

          let respData = this.getRespData(response)

          console.log(respData);

          this.barcode = ''

          if (respData?.label?.uuid) {
            this.openNotify('success', 'common_notificationFilesAddedToPrint')

            // let routeData = this.$router.resolve({
            //   path: this.$store.getters.GET_PATHS.getPdfFile + '/' + respData.label.uuid
            // })
            //
            // window.open(routeData.href, '_blank');
          } else {
            this.openNotify('error', 'common_notificationFilesNotFoundToPrint')
          }


          // if (link !== '') {
          //   window.open(`${window.location.origin}${link}`, '_blank');
          // }
          // else if (type === 'barcode') {
          //   this.barcodeErrorItem = this.barcode
          // }

        }).catch(error => this.createErrorLog(error))

      }
    },
  }
</script>

<style scoped>

</style>
